import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../../components/layout";
import PhotoRight from "../../components/photo-right";
import ServiceCard from "../../components/service-card";
import BeeBanner from "../../components/bee-banner";
import SVGWwdBgPoly1 from "../../components/SVG/wwd-bg-poly-1";
import SVGFBWwd from "../../components/SVG/flying-bee-wwd";
import SVGLeadership from "../../components/SVG/leadership";
import SVGSoftware from "../../components/SVG/software";
import SVGERWwd from "../../components/SVG/employee-relation-wwd";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const WhatWeDo = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What We Do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
		],
	};

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="What We Do | Hive HR Solutions"
					description="Our goal is to offer a service that is accessible, credible, practical and tailored to your business. / placeholder"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/what-we-do`,
						title: "What We Do | Hive HR Solutions",
						description:
							"Our goal is to offer a service that is accessible, credible, practical and tailored to your business. / placeholder",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<section className="pt-md-5 pt-lg-0">
					<PhotoRight
						height="25rem"
						width="37rem"
						img={heroImg}
						imgAlt={data.heroImg?.altText}
						heading={<span>What we do</span>}
						text={
							<span>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
									cursus sed eu nunc. Maecenas neque ultrices egestas eu. Non
									lorem sed integer eros, purus orci nulla dignissim. Consequat
									adipiscing et enim id.
								</p>
							</span>
						}
					/>
				</section>
				<section className="pt-md-6  pb-lg-10 position-relative">
					<SVGWwdBgPoly1
						style={{ zIndex: -2 }}
						className="position-absolute top--60 d-none d-lg-block"
					/>
					<Container>
						<Row className="justify-content-center">
							<ServiceCard
								icon={<SVGLeadership width={100} />}
								header="Leadership, culture and development"
								text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget cursus sed eu nunc. Maecenas neque ultrices egestas eu. Non lorem sed integer eros, purus orci nulla dignissim. Consequat adipiscing et enim id."
								url="/what-we-do/leadership-culture-and-development"
								linkText="Read More"
							/>
							<ServiceCard
								icon={<SVGSoftware width={100} />}
								header="Recruitment and software"
								text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget cursus sed eu nunc. Maecenas neque ultrices egestas eu. Non lorem sed integer eros, purus orci nulla dignissim. Consequat adipiscing et enim id."
								url="/what-we-do/recruitment-and-software"
								linkText="Read More"
							/>
							<ServiceCard
								icon={<SVGERWwd width={100} />}
								header="Employee relations"
								text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget cursus sed eu nunc. Maecenas neque ultrices egestas eu. Non lorem sed integer eros, purus orci nulla dignissim. Consequat adipiscing et enim id."
								url="/what-we-do/employee-relations"
								linkText="Read More"
							/>
						</Row>
					</Container>
				</section>
				<section className="position-relative">
					<SVGFBWwd
						style={{ zIndex: -2 }}
						className="position-absolute bottom--50 d-none d-lg-block"
					/>
					<BeeBanner
						headline="Ready to speak to us?"
						btnTxt="Call us"
						btnLink="tel:+441403627630"
					/>
				</section>
			</Layout>
		);
	}
};

export default WhatWeDo;
