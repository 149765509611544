import * as React from "react";

const SVGERWwd = (props) => (
	<svg
		width={137}
		height={137}
		viewBox="0 0 137 137"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M87.5278 25C91.1004 25 94.4017 26.906 96.188 30L115.415 63.3013C117.201 66.3953 117.201 70.2073 115.415 73.3013L96.188 106.603C94.4017 109.697 91.1004 111.603 87.5278 111.603L49.0748 111.603C45.5021 111.603 42.2008 109.697 40.4145 106.603L21.188 73.3013C19.4017 70.2073 19.4017 66.3953 21.188 63.3013L40.4145 30C42.2008 26.906 45.5021 25 49.0748 25L87.5278 25Z"
			fill="#fbba00"
		/>
		<path
			d="M71.4198 69.31C70.2951 69.2981 69.199 68.9538 68.2696 68.3203C67.3401 67.6868 66.6189 66.7925 66.1966 65.75C65.7744 64.7075 65.6701 63.5633 65.8967 62.4616C66.1234 61.3599 66.6709 60.3499 67.4704 59.5587C68.2699 58.7676 69.2856 58.2306 70.3897 58.0155C71.4937 57.8004 72.6367 57.9167 73.6747 58.3498C74.7128 58.783 75.5995 59.5135 76.2232 60.4495C76.8469 61.3856 77.1797 62.4852 77.1798 63.61C77.1692 65.1288 76.5571 66.5816 75.4774 67.65C74.3978 68.7184 72.9387 69.3153 71.4198 69.31ZM71.4198 59.91C70.6907 59.9218 69.9813 60.1488 69.3808 60.5625C68.7803 60.9762 68.3154 61.5581 68.0445 62.2351C67.7736 62.9122 67.7089 63.6541 67.8583 64.3679C68.0078 65.0816 68.3649 65.7353 68.8846 66.2467C69.4044 66.7582 70.0637 67.1047 70.7797 67.2426C71.4958 67.3806 72.2366 67.3039 72.9092 67.0222C73.5818 66.7405 74.1562 66.2663 74.5601 65.6592C74.9641 65.0521 75.1797 64.3392 75.1798 63.61C75.1692 62.6215 74.7678 61.6775 74.0632 60.9841C73.3587 60.2908 72.4083 59.9046 71.4198 59.91Z"
			fill="black"
		/>
		<path
			d="M71.4198 69.31C70.2951 69.2981 69.199 68.9538 68.2696 68.3203C67.3401 67.6868 66.6189 66.7925 66.1966 65.75C65.7744 64.7075 65.6701 63.5633 65.8967 62.4616C66.1234 61.3599 66.6709 60.3499 67.4704 59.5587C68.2699 58.7676 69.2856 58.2306 70.3897 58.0155C71.4937 57.8004 72.6367 57.9167 73.6747 58.3498C74.7128 58.783 75.5995 59.5135 76.2232 60.4495C76.8469 61.3856 77.1797 62.4852 77.1798 63.61C77.1692 65.1288 76.5571 66.5816 75.4774 67.65C74.3978 68.7184 72.9387 69.3153 71.4198 69.31ZM71.4198 59.91C70.6907 59.9218 69.9813 60.1488 69.3808 60.5625C68.7803 60.9762 68.3154 61.5581 68.0445 62.2351C67.7736 62.9122 67.7089 63.6541 67.8583 64.3679C68.0078 65.0816 68.3649 65.7353 68.8846 66.2467C69.4044 66.7582 70.0637 67.1047 70.7797 67.2426C71.4958 67.3806 72.2366 67.3039 72.9092 67.0222C73.5818 66.7405 74.1562 66.2663 74.5601 65.6592C74.9641 65.0521 75.1797 64.3392 75.1798 63.61C75.1692 62.6215 74.7678 61.6775 74.0632 60.9841C73.3587 60.2908 72.4083 59.9046 71.4198 59.91Z"
			fill="black"
		/>
		<path
			d="M74.9098 70.65C70.4743 69.8782 65.9082 70.5806 61.9098 72.65C61.626 72.8076 61.391 73.0402 61.2305 73.3224C61.07 73.6046 60.9902 73.9255 60.9998 74.25V77.81C60.9998 78.0752 61.1052 78.3295 61.2927 78.5171C61.4802 78.7046 61.7346 78.81 61.9998 78.81C62.265 78.81 62.5194 78.7046 62.7069 78.5171C62.8944 78.3295 62.9998 78.0752 62.9998 77.81V74.38C66.7075 72.5196 70.9238 71.9293 74.9998 72.7L74.9098 70.65Z"
			fill="black"
		/>
		<path
			d="M86 75H79.3V73.52C79.3 73.2548 79.1946 73.0004 79.0071 72.8129C78.8196 72.6254 78.5652 72.52 78.3 72.52C78.0348 72.52 77.7804 72.6254 77.5929 72.8129C77.4054 73.0004 77.3 73.2548 77.3 73.52V75H70C69.7348 75 69.4804 75.1054 69.2929 75.2929C69.1054 75.4804 69 75.7348 69 76V86C69 86.2652 69.1054 86.5196 69.2929 86.7071C69.4804 86.8947 69.7348 87 70 87H86C86.2652 87 86.5196 86.8947 86.7071 86.7071C86.8946 86.5196 87 86.2652 87 86V76C87 75.7348 86.8946 75.4804 86.7071 75.2929C86.5196 75.1054 86.2652 75 86 75ZM85 85H71V77H77.3V77.41C77.3 77.6752 77.4054 77.9296 77.5929 78.1171C77.7804 78.3047 78.0348 78.41 78.3 78.41C78.5652 78.41 78.8196 78.3047 79.0071 78.1171C79.1946 77.9296 79.3 77.6752 79.3 77.41V77H85V85Z"
			fill="black"
		/>
		<path d="M74.8101 80.42H80.7701V81.82H74.8101V80.42Z" fill="black" />
		<path
			d="M63.84 65.24C61.0714 65.2863 58.3509 65.9707 55.89 67.24C55.6224 67.3814 55.3982 67.5928 55.2414 67.8517C55.0847 68.1107 55.0012 68.4073 55 68.71V71.81C55 72.0752 55.1054 72.3296 55.2929 72.5171C55.4804 72.7046 55.7348 72.81 56 72.81C56.2652 72.81 56.5196 72.7046 56.7071 72.5171C56.8946 72.3296 57 72.0752 57 71.81V68.91C59.3513 67.7396 61.9546 67.1661 64.58 67.24C64.2365 66.6133 63.9872 65.9394 63.84 65.24Z"
			fill="black"
		/>
		<path
			d="M86.1098 67.23C83.9026 66.0722 81.4769 65.3908 78.9898 65.23C78.8433 65.9281 78.5975 66.6016 78.2598 67.23C80.6028 67.2861 82.9045 67.8598 84.9998 68.91V71.81C84.9998 72.0752 85.1051 72.3296 85.2927 72.5171C85.4802 72.7046 85.7345 72.81 85.9998 72.81C86.265 72.81 86.5193 72.7046 86.7069 72.5171C86.8944 72.3296 86.9998 72.0752 86.9998 71.81V68.71C87.0004 68.4056 86.9178 68.1068 86.7609 67.846C86.6041 67.5852 86.3789 67.3721 86.1098 67.23Z"
			fill="black"
		/>
		<path
			d="M63.6601 63.61V62.94C62.8767 62.8352 62.1639 62.4323 61.6702 61.8151C61.1764 61.198 60.9397 60.4142 61.0092 59.6269C61.0788 58.8396 61.4494 58.1094 62.0437 57.5885C62.6381 57.0675 63.4105 56.7958 64.2001 56.83C65.0214 56.8285 65.8108 57.1479 66.4001 57.72C66.92 57.2912 67.491 56.9284 68.1001 56.64C67.4197 55.8577 66.5182 55.2999 65.5145 55.0401C64.5107 54.7803 63.4518 54.8308 62.4773 55.1849C61.5028 55.5389 60.6585 56.18 60.0556 57.0235C59.4527 57.867 59.1196 58.8734 59.1001 59.91C59.1211 61.1788 59.6143 62.3941 60.4834 63.3187C61.3525 64.2433 62.5351 64.8107 63.8001 64.91C63.7153 64.4816 63.6684 64.0466 63.6601 63.61Z"
			fill="black"
		/>
		<path
			d="M77.7701 54.83C77.0829 54.8301 76.4026 54.9673 75.769 55.2334C75.1354 55.4996 74.5613 55.8894 74.0801 56.38C74.7561 56.625 75.3954 56.9614 75.9801 57.38C76.4469 57.0559 76.993 56.8646 77.56 56.8265C78.127 56.7884 78.6938 56.9049 79.1998 57.1635C79.7058 57.4222 80.1322 57.8133 80.4334 58.2952C80.7346 58.7772 80.8993 59.3318 80.9101 59.9C80.9038 60.4836 80.7323 61.0536 80.4155 61.5438C80.0987 62.034 79.6496 62.4245 79.1201 62.67C79.1603 62.9784 79.1804 63.289 79.1801 63.6C79.1774 64.002 79.144 64.4031 79.0801 64.8C80.1695 64.5199 81.1357 63.8876 81.8284 63.0014C82.5211 62.1151 82.9014 61.0248 82.9101 59.9C82.8969 58.5473 82.3489 57.2548 81.3858 56.3048C80.4227 55.3549 79.1228 54.8246 77.7701 54.83Z"
			fill="black"
		/>
	</svg>
);

export default SVGERWwd;
