import * as React from "react";

const SVGWwdBgPoly1 = (props) => (
	<svg
		width={285}
		height={641}
		viewBox="0 0 285 641"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M106.784 198.924C110.357 198.924 113.658 200.83 115.445 203.924L208.694 365.436C210.48 368.53 210.48 372.342 208.694 375.436L115.445 536.948C113.658 540.042 110.357 541.948 106.784 541.948L-79.7139 541.948C-83.2865 541.948 -86.5878 540.042 -88.3741 536.948L-181.623 375.436C-183.41 372.342 -183.41 368.53 -181.623 365.436L-88.3741 203.924C-86.5878 200.83 -83.2865 198.924 -79.7139 198.924L106.784 198.924Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
		<path
			d="M92.7842 99.0227C96.3569 99.0227 99.6581 100.929 101.444 104.023L194.694 265.535C196.48 268.629 196.48 272.441 194.694 275.535L101.444 437.047C99.6581 440.141 96.3569 442.047 92.7842 442.047L-93.7141 442.047C-97.2868 442.047 -100.588 440.141 -102.374 437.047L-195.624 275.535C-197.41 272.441 -197.41 268.629 -195.624 265.535L-102.374 104.023C-100.588 100.929 -97.2868 99.0227 -93.7141 99.0227L92.7842 99.0227Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGWwdBgPoly1;
